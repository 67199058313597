
import { GetUserProfileResponse } from '@/plugins/api';
import { Component, Vue } from 'vue-property-decorator';
import Cards from '../components/ProfileNavCards.vue';
@Component({
  components: {
    Cards
  },
})
export default class LandingPage extends Vue {
  private profile: GetUserProfileResponse|null = null;
  async getProfile () {
    try {
      const res = await this.$api.getProfile();
      this.profile = res.data;
      
    } catch (err) {
      console.log(err);
    }
  }

  mounted () {
    this.$nextTick(() => this.getProfile())
  }
}
