
import { GetUserProfileResponse } from "@/plugins/api";
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class ProfileNavCards extends Vue {
  @Prop() profile!: GetUserProfileResponse;
  options = [
    {image: require('../assets/new_giveaway.png'), title: "قرعه کشی", subtitle: 'جدید',
      style: {background: 'linear-gradient(201.62deg, #7976C0 6.26%, #A9A7DB 100%)'},
      disabled: false,
      button_text: "ایجاد قرعه کشی",
      to: '/profile/new-giveaway',
      text: 'با انجام مراحل به سادگی و با کمترین هزینه و زمان ممکن قرعه کشی اینستاگرامی خود را برگزار کنید.'},
    {image: require('../assets/list_giveaways.png'),title: "مشاهده", subtitle: 'در حال برگزاری',
      style: {background: 'linear-gradient(201.62deg, #E6583D 17.57%, #F8957B 100%)'},
      button_text: "مشاهده جزئیات",
      to: '/profile/active-giveaways',
      disabled: true,
      text: 'لیست قرعه‌کشی‌های در حال برگزاری و جزئیات پست اینستاگرامی مورد نظرتان را مشاهده کنید.'},
    {image: require('../assets/done_giveaways.png'), title: "تاریخچه", subtitle: 'برگزار شده',
      style: {background: 'linear-gradient(201.62deg, #EB96AA 29.13%, #E7B5C1 100%)'},
      button_text: "مشاهده جزئیات",
      to: '/profile/archive-giveaways',
      disabled: true,
      text: 'لیست قرعه‌گشی های برگزار شده را مشاهده خواهید کرد و قابلیت اشتراک گذاری جزئیات و برنده هارا خوهید داشت.'}
  ]
  mounted (){
    this.options[0].disabled = !this.profile.can_create_giveaway;
    this.options[1].disabled = this.profile.active_count==0;
    this.options[2].disabled = this.profile.done_count==0;
  }
}
