import { render, staticRenderFns } from "./ProfileNavCards.vue?vue&type=template&id=5422a150&scoped=true"
import script from "./ProfileNavCards.vue?vue&type=script&lang=ts"
export * from "./ProfileNavCards.vue?vue&type=script&lang=ts"
import style0 from "./ProfileNavCards.vue?vue&type=style&index=0&id=5422a150&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5422a150",
  null
  
)

export default component.exports